import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Module } from '@app/models';
import { Role } from '@app/models/users/user.model';
import { UsersService } from '@app/services/users/users.service';
import { treeToList } from '@app/utils/tree-to-list';
import { Subscription, first } from 'rxjs';

export interface IBreadcrumb {
  name: string;
  title: string;
  icon: string;
  route: null | string;
}
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() modules: string[] | IBreadcrumb[] | Module[] = [];
  treeToList = treeToList;

  private role: Role;
  private currentRouteUrl = '';
  currentModule: IBreadcrumb | Module;

  private subscribe: Record<string, Subscription> = {};

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _user: UsersService
  ) { }

  ngOnInit(): void {
    this._initialLoad();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.modules?.length) this._initialLoad();
  }

  ngOnDestroy(): void {
    Object.values(this.subscribe).forEach((s) => s.unsubscribe());
  }

  private _initialLoad(): void {
    this._user.userRole$.pipe(first()).subscribe((role) => {
      this.role = role;
      if (this.modules.length) {
        this._updateModules();
      } else {
        this.createBreadcrumbs(this._getRoute());
        this.subscribe['router'] = this._router.events.subscribe((event) => {
          this.createBreadcrumbs(this._getRoute());
        });
      }
    });
  }

  private _getRoute(): string {
    return this._activatedRoute.snapshot.queryParams['module'] ?? this._router.url;
  }

  private _updateModules(): void {
    const modules: IBreadcrumb[] | Module[] = this.modules.map((module) => {
      if (typeof module === 'string') {
        return this.role.modules
          .map((m) => this.searchTree(m, module))
          .find((m) => m !== null);
      }

      return module;
    });

    this.modules = modules;
    this.currentModule = modules[modules.length - 1];
  }

  createBreadcrumbs(route: string): void {
    if (route === undefined || this.currentRouteUrl === route) {
      return;
    }

    this.currentRouteUrl = route;
    route = route.split('?')[0];

    const currentModule = this.role.modules
      .map((module) => this.searchTree(module, route))
      .find((module) => module !== null);

    if (!currentModule) {
      this.currentModule = null;
      this.modules = [];
      return;
    }

    this.currentModule = {...currentModule};
    delete this.currentModule.items;
    const tree = this.getParent(this.currentModule);
    this.modules = this.treeToList([tree]);
  }

  searchTree(
    module: Module,
    value: string | number,
    key = 'route'
  ): Module | null {
    if (module[key] == value) {
      return module;
    }

    if (module.items?.length > 0) {
      let result = null;
      for (let i = 0; result == null && i < module.items.length; i++) {
        result = this.searchTree(module.items[i], value, key);
      }
      return result;
    }

    return null;
  }

  getParent(module: Module): Module {
    if (module.parent_id !== null) {
      const parent = this.role.modules.find((value) =>
        this.searchTree(value, module.parent_id, 'id')
      );

      if (parent) {
        return this.getParent({...parent, items: [module]});
      }
    }

    return module;
  }

  navigate($route: string): void {
    const route = this.currentRouteUrl.split('?')[0];

    if ($route !== route) {
      const parentModule = this._user.getParentModuleById(
        (this.modules[0] as Module).id
      );
      if (parentModule) {
        this._user.setCurrentModule(parentModule);
      }
      
      this._router.navigate([$route]);
    }
  }
}