import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { FormGroupModule } from "@app/modules/@cdk/forms/input/form-group/form-group.module";
import { InputModule } from "@app/modules/@cdk/forms/input/input.module";
import { ButtonModule } from "@app/modules/@cdk/forms/button/button.module";
import { CheckboxModule } from "@app/modules/@cdk/forms/checkbox/checkbox.module";
import { SelectModule } from "@app/modules/@cdk/forms/select/select.module";

import { MatTooltipModule } from "@angular/material/tooltip";

import { ContractSignComponent } from "./contract-sign.component";
import { ContractAgentComponent } from "./forms/contract-agent/contract-agent.component";
import { ContractAgencyComponent } from "./forms/contract-agency/contract-agency.component";
import { ContractTermsComponent } from "./forms/contract-terms/contract-terms.component";
import { ContractCommissionInAdvanceAgentOfAgencyComponent } from "./forms/contract-commission-in-advance-agent-of-agency/contract-commission-in-advance-agent-of-agency.component";
import { ContractCommissionInAdvanceDirectAgentComponent } from "./forms/contract-commission-in-advance-direct-agent/contract-commission-in-advance-direct-agent.component";
import { IconModule } from "@app/modules/@cdk";

@NgModule({
  declarations: [
    ContractSignComponent,
    ContractAgentComponent,
    ContractAgencyComponent,
    ContractTermsComponent,
    ContractCommissionInAdvanceAgentOfAgencyComponent,
    ContractCommissionInAdvanceDirectAgentComponent,
  ],

  imports: [
    CommonModule,
    FormGroupModule,
    InputModule,
    ButtonModule,
    CheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,
    SelectModule,
    IconModule,
  ],
  exports: [ContractSignComponent],
})
export class ContractSignModule {}
